import * as React from 'react';
import styled from 'styled-components';
import Hero from '../components/Hero';
import HeroImage from '../components/HeroImage';
import Section from '../components/Section';
import Wrapper from '../components/Wrapper';
import background from '../assets/images/about/hero.jpeg';
import aman from '../assets/images/about/aman.jpeg';

const Main = styled.main`
  line-height: 1.75;

  h4 {
    color: #101119;
    font-weight: 700;
    max-width: 700px;
    font-size: 1.25rem;
    margin-bottom: 0;
    line-height: 1;
  }

  h3 {
    color: #101119;
    font-weight: 700;
    max-width: 700px;
    font-size: 2rem;
  }

  p {
    font-size: 16px;
    max-width: 700px;
    margin: 1rem 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const Col = styled.div`
  width: 100%;
  padding: 0 2rem;

  p {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 2rem 0;
  }
`;

const ColSmall = styled.div`
  width: 35%;
  padding: 0 2rem;

  @media (max-width: 600px) {
    width: 100%;
    padding: 2rem 0;
  }
`;

const ColLarge = styled.div`
  width: 65%;
  padding: 0 2rem;

  @media (max-width: 600px) {
    width: 100%;
    padding: 1rem 0;
  }
`;

const Lead = styled.p`
  font-size: 26px !important;
  font-family: 'Averia Serif Libre';
  padding: 1.5rem 0;
`;

const Image = styled.img`
  padding: 1.5rem 0;
  position: relative;
  top: 1.5rem;
  width: 100%;
`;

const AboutPage = () => {
  return (
    <Main>
      <HeroImage src={background} key="video-bball" />
      <Hero>
        <h1>
          <span>The</span>
          <span>goal</span>
          <span>is</span>
          <span>to</span>
          <span>be</span>
          <span>the</span>
          <span>premier</span>
          <span>sports</span>
          <span>agency</span>
          <span>in</span>
          <span>North</span>
          <span>America.</span>
          <span>We</span>
          <span>channel</span>
          <span>the</span>
          <span>power</span>
          <span>of</span>
          <span>sports</span>
          <span>to</span>
          <span>help</span>
          <span>our</span>
          <span>clients</span>
          <span>reach</span>
          <span>their</span>
          <span>fullest</span>
          <span>potential.</span>
        </h1>
      </Hero>
      <Section>
        <Wrapper>
          <Row>
            <Col>
              <h3>About</h3>
              <p>
                PNW Sports Group strives to be the premier boutique sports
                agency in North America. We channel the power of sports to help
                our clients reach their fullest potential.
              </p>
              <p>
                As a boutique sports agency, we consider each one of our clients
                to be part of our family. It is our primary goal to assist each
                client at every stage of their professional career including
                facilitating a path to successful transition to a post-career
                life.
              </p>
              <p>
                With the historic decision to allow student athletes to
                capitalize on their Name, Image and Likeness, we at PNW Sports
                Group were quick to create unique plans to optimize and monetize
                an athlete’s value prior to commencing their professional
                career. Please contact us to discuss further.
              </p>
            </Col>
          </Row>

          <Row>
            <ColSmall>
              <Image src={aman} alt="basketball" />
            </ColSmall>

            <ColLarge>
              <h3>Aman Dhesi, President</h3>
              <p>
                Aman is the President and Founder of PNW Sports Group. He is
                certified as an agent by both the National Basketball Players
                Association (NBPA) and the National Football League Players
                Association (NFLPA). With years of experience in the industry,
                Aman is focused in every aspect of professional athlete
                representation including contract negotiations, pre-draft
                preparation, marketing and public relations, community outreach,
                and general client management during and post-career.
              </p>
              <p>
                Prior to founding PNW Sports Group, Aman practiced as a
                corporate/commercial lawyer with a focus on M&A, public and
                private equity financings, business structuring and other
                corporate matters. He obtained both his BA (2013) and JD (2016)
                from the University of British Columbia.
              </p>
            </ColLarge>
          </Row>

          <Row>
            <ColSmall></ColSmall>

            <ColLarge>
              <h4>Keith Moss, Vice President, Basketball Operations</h4>
              <p>
                Keith Moss is a highly accomplished individual with a remarkable
                career in the world of basketball. With over 30 years of
                experience, he has made significant contributions as a college
                basketball coach, NBA agent, and player development coach.
              </p>

              <p>
                Keith's journey began as a college basketball coach, where he
                played a pivotal role in leading the San Jose State team to the
                NCAA tournament in 1996, marking their last appearance in the
                Big Dance. This achievement showcased his exceptional coaching
                abilities and strategic mindset.
              </p>

              <p>
                After his successful stint as a college coach, Keith
                transitioned into the role of an NBA agent. His first client,
                Olivier Saint-Jean, made history as the first French-born NBA
                player ever. Saint-Jean's selection as the 11th pick in the 1997
                NBA draft marked the beginning of Keith's journey in building
                Comsport USA, one of the largest NBA agencies.
              </p>

              <p>
                Keith's expertise in talent scouting and player development is
                evident through his efforts in bringing over 30 players to
                America to play in high school, prep school, and college. Many
                of these players have gone on to achieve great success, with
                several making it to the NBA. Keith's keen eye for talent and
                dedication to nurturing young athletes have played a significant
                role in their development and success.
              </p>

              <p>
                In 2008, Keith returned to college coaching at Saint Mary's
                College, where he not only coached but also recruited
                exceptional players such as Patty Mills and Matthew Dellavedova.
                His ability to identify and develop talent has consistently been
                recognized throughout his career.
              </p>

              <p>
                Keith's passion for the game led him to work as a player
                development coach for the Dallas Mavericks, where he further
                honed his skills and contributed to the growth of professional
                athletes.
              </p>

              <p>
                With an impressive track record and a wealth of experience,
                Keith Moss continues to make a lasting impact on the world of
                basketball. His dedication, expertise, and commitment to the
                sport have solidified his reputation as a highly respected
                figure in the industry.
              </p>
            </ColLarge>
          </Row>
        </Wrapper>
      </Section>
    </Main>
  );
};

export default AboutPage;
